import React, {useState, useEffect} from "react"
import {useDispatch, useSelector} from 'react-redux'
import { navigate } from "gatsby"
import { useQueryParam, StringParam } from "use-query-params";
import { Steps, Modal } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'

import {requestAccess} from '../../../state/actions'
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const RequestAccessPage = (props) => {
  const dispatch = useDispatch()
  let accessRequestState = useSelector(state => state.accessRequest)
  let mac = useQueryParam("id", StringParam)[0];
  let ssid = useQueryParam("ssid", StringParam)[0];
  let site = props["*"];

  let [name, setName] = useState('');

  useEffect(() => {
    if(!site || !mac){
      navigate("/error");
    }
  }, [])

  return(
    <Layout>
      <SEO title="Home" />
      <div className="section bg-primary overflow-hidden">
            <div className="container z-2">
                <div className="row mt-1 mb-6 my-mb-4 text-white justify-content-center">
                    <div className="col-12 col-md-10">
                        <div className="mb-3 d-block">
                          <div className="text-light">
                          <Steps current={accessRequestState.result}>
                            <Steps.Item />
                            <Steps.Item />
                            <Steps.Item />
                          </Steps> 
                          </div> 
                        </div>
                        <div className="text-center mt-5">
                          <p className="my-4 px-0 px-lg-7 lead">Willkommen im Netzwerk <br/> <span className="font-weight-bold">{ssid}</span><br/>{getDescriptionForStep(accessRequestState.result)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section className="section pt-0">
            <div className="container">
                <div className="col-12">
                    <div className="row justify-content-center mt-n7 z-2">
                        <div className="col-12 col-md-8">
                            <div className="card border-light">
                                <div className="card-body p-5">
                                  <div className="d-flex flex-column"> 
                                    <div className="name-field my-4 mx-auto col-12 col-md-10"><label for="nameInput">Dein Name</label> <input type="text" className="form-control" id="nameInput" onChange={(event) => setName(event.target.value)} disabled={accessRequestState.isRequested}/></div>
                                    <div className="mx-auto mt-4">
                                      {renderRequestButton(site, mac, name, dispatch, accessRequestState)}
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {renderModal(accessRequestState.result)}
     </Layout>
  )
  

  
}

const getDescriptionForStep = (state) => {
  if(state === 2){
    return "Los geht's! Du kannst jetzt surfen."
  }
  if(state === 1){
    return "Deine Anfrage ist abgeschickt. Warte bis sie bestätigt ist."
  }
  return "Gib deinen Namen in das untenstehende Feld ein, und beantrage Zugriff auf das Internet"
}

const handleSubmit = (site, mac, name, dispatch) => {
  if(!name){
    console.log("//TODO Error no name")
    return;
  }
  dispatch(requestAccess(site, mac, name));
}

const renderOnlineButton = () => {
  return(
    <button className="btn btn-lg btn-primary" type="button" onClick={() => window.location.replace("https://www.zorrillamedia.de")}>Online gehen</button>
  )
}

const renderModal = (state) => {
  return(
    <Modal backdrop={true} show={state > 1 || typeof state === "string"} size="xs">
      <Modal.Header closeButton={false}>
        <Modal.Title>{state === 2 ? "Du bist online!" : "Raus hier"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof state === "string" && state}
        {state === 2 && "Los geht's! Du kannst jetzt surfen."}
      </Modal.Body>
      <Modal.Footer className="text-center">
      {state === 2 ? renderOnlineButton() : <></>}
      </Modal.Footer>
  </Modal>
  )
}

const renderRequestButton = (site, mac, name, dispatch, accessRequestState) => {
  return(
    <button className="btn btn-lg btn-primary" type="button" onClick={() => handleSubmit(site, mac, name, dispatch)} disabled={accessRequestState.isRequested}>Zugriff beantragen</button>
  )
}

export default RequestAccessPage
